import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { ContributorImage } from 'components/PostLayout/Contributors'

export default function TeamMembers({ team }: { team: string }) {
    const {
        team: { nodes },
    } = useStaticQuery(query)
    const teamMembers = nodes
        .filter((node) => node?.frontmatter?.team?.some((teamName) => teamName === team))
        .sort((l, r) => (l.frontmatter.teamLead?.includes(team) ? -1 : r.frontmatter.teamLead?.includes(team) ? 1 : 0))
    if (!teamMembers || teamMembers.length <= 0) return null

    return (
        <>
            <h4>Team members</h4>
            <ul className="m-0 grid list-none p-0 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                {teamMembers.map((member) => {
                    const { name, headshot, jobTitle, teamLead, country } = member?.frontmatter
                    const parent = member?.parent
                    const headshotImage = headshot || parent?.cover?.image
                    return (
                        <li className="!m-0 flex items-center space-x-4 py-4" key={name}>
                            <figure className="mb-0">
                                <ContributorImage image={headshotImage && getImage(headshotImage)} />
                            </figure>
                            <div>
                                <span className="flex items-center space-x-2 md:flex-row">
                                    <p className="!m-0 !text-lg !font-bold !leading-none">{name}</p>
                                    {country && (
                                        <span className="!leading-none">
                                            {country === 'world' ? (
                                                '🌎'
                                            ) : (
                                                <ReactCountryFlag svg countryCode={country} />
                                            )}
                                        </span>
                                    )}
                                    {teamLead?.includes(team) && (
                                        <span className="inline-block rounded-sm border-2 border-red/50 bg-white px-2 py-1 text-[12px] font-semibold !leading-none text-red">
                                            Team lead
                                        </span>
                                    )}
                                </span>
                                <p className="!mb-0 !mt-1 !text-sm !leading-none opacity-50">{jobTitle}</p>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

const query = graphql`
    query {
        team: allMdx(filter: { fields: { slug: { regex: "/^/team//" } } }, sort: { fields: frontmatter___startDate }) {
            nodes {
                frontmatter {
                    headshot {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    team
                    jobTitle
                    name
                    country
                    github
                    teamLead
                }
                parent {
                    ... on GoogleDocs {
                        cover {
                            image {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
