import { MDXProvider } from '@mdx-js/react'
import { GithubIcon } from 'components/GithubIcon'
import { LinkedinIconPersonal } from 'components/LinkedinIcon'
import LinkedinButton from 'components/LinkedinButton'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { kebabCase } from 'lib/utils'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { shortcodes } from '../../mdxGlobalComponents'

export default function Team() {
    const {
        team: { teamMembers },
    } = useStaticQuery(query)
    return (
        <ul className="m-0 list-none p-0">
            {teamMembers &&
                teamMembers.map((teamMember) => {
                    const { id, body, excerpt, fields, frontmatter, parent } = teamMember
                    const { headshot, jobTitle, name, pronouns, country, github, linkedin } = teamMember?.frontmatter
                    const nameAndPronouns = pronouns ? `${name} (${pronouns})` : name
                    const title = `${nameAndPronouns}, ${jobTitle}`
                    const featuredHeadShot = headshot || parent?.cover?.image
                    const image = featuredHeadShot && getImage(featuredHeadShot)
                    return (
                        <li key={id}>
                            <div className="team-row">
                                <div className="team-left-text">
                                    <h3 id={kebabCase(name) + '-' + kebabCase(jobTitle)}>{title}</h3>
                                    {linkedin && <LinkedinIconPersonal profileId={linkedin} />}
                                    {/* <GithubIcon username={github} /> */}
                                    {/* <LinkedinButton
                    className="text-white hover:text-white"
                    profileId='ahmed-elazab-36b7ba1a'
                    theme='light'
                    size='large'
                    orientation='horizontal'
                    showLink='true'
                    linkText='Follow iCloud-Ready on linkedin'
                >
                    Follow
                </LinkedinButton> */}
                                    <div className="team-left-bio">
                                        <MDXProvider components={shortcodes}>
                                            <MDXRenderer>{body}</MDXRenderer>
                                        </MDXProvider>
                                    </div>
                                </div>

                                <div className="team-center-space"></div>

                                <div className="team-right-image relative">
                                    <GatsbyImage
                                        objectFit="contain"
                                        className="w-full"
                                        image={image}
                                        alt={title}
                                        title={title}
                                    />
                                    <span className="absolute top-2 right-2 mt-8 text-4xl sm:text-4xl">
                                        {country === 'world' ? '🌎' : <ReactCountryFlag svg countryCode={country} />}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )
                })}
        </ul>
    )
}

const query = graphql`
    query TeamQuery {
        team: allMdx(filter: { fields: { slug: { regex: "/^/team//" } } }, sort: { fields: frontmatter___startDate }) {
            teamMembers: nodes {
                id
                body
                frontmatter {
                    headshot {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    jobTitle
                    name
                    pronouns
                    country
                    github
                    linkedin
                }
                parent {
                    ... on GoogleDocs {
                        cover {
                            image {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
