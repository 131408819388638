import React from 'react'
import CheckIcon from '../../images/check.svg'
import MinusIcon from '../../images/x.svg'
import InfoIcon from '../InfoIcon/Index'
import Link from 'components/Link'

const features = [
    { key: 'eventCapture', name: 'Event capture', url: 'https://icloud-ready.com/tutorials/event-tracking-guide' },
    {
        key: 'autoCapture',
        name: 'Autocapture',
        url: 'https://icloud-ready.com/docs/user-guides/events#autocapture-event-tracking',
    },
    {
        key: 'userIdentification',
        name: 'User identification',
        url: 'https://icloud-ready.com/docs/integrate/identifying-users',
    },
    { key: 'realestateCrm', name: 'Real estate crm', url: 'https://icloud-ready.com/docs/user-guides/recordings' },
    {
        key: 'RealEstateMarketing',
        name: 'Real estate marketing',
        url: 'https://icloud-ready.com/docs/user-guides/real-estate-marketing',
    },
    {
        key: 'MobileApps',
        name: 'Mobile apps',
        url: 'https://icloud-ready.com/docs/user-guides/real-estate-mobile-apps',
    },
    {
        key: 'TransactionManagement',
        name: 'Transaction management',
        url: 'https://icloud-ready.com/docs/user-guides/transaction-management',
    },
    {
        key: 'stakeholderManagement',
        name: 'Stakeholder management',
        url: 'https://icloud-ready.com/docs/user-guides/stakeholder-management',
    },
] as const

export type LibraryFeaturesProps = {
    availability?: Record<typeof features[number]['key'], boolean>
}

export const LibraryFeatures = ({ availability }: LibraryFeaturesProps) => {
    return availability ? (
        <div className="-mt-4 mb-4 space-y-2 border-b border-dashed border-gray-accent-light pb-4 dark:border-gray-accent-dark lg:mt-4 lg:border-t lg:pt-3">
            <h5 className="!m-0 pt-2 pb-1 text-base font-semibold text-primary/50 dark:text-primary-dark/50">
                Which features are available in this library?
            </h5>

            <ul className="m-0 grid list-none grid-cols-1 gap-x-2 gap-y-1 p-0 sm:grid-cols-2 lg:grid-cols-3">
                {features.map((feature) => (
                    <li key={feature.key} className="flex items-center">
                        {availability[feature.key] ? (
                            <img src={CheckIcon} alt="Available" className="h-4 w-4" aria-hidden="true" />
                        ) : (
                            <img src={MinusIcon} alt="Not available" className="h-4 w-4" aria-hidden="true" />
                        )}
                        <span className="mr-.5 ml-2 text-[15px]">{feature.name}</span>
                        <Link to={feature.url} className="group p-1 hover:!bg-none focus:!bg-none active:!bg-none">
                            <InfoIcon className="relative h-4 w-4 transform opacity-75 transition-all active:top-[1px] active:scale-[1.1] group-hover:scale-[1.2] group-hover:opacity-100 xl:h-3.5 xl:w-3.5" />
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null
}

export default LibraryFeatures
